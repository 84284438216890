import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">



<path d="M1507 6203 c-4 -3 -7 -195 -7 -425 l0 -419 88 3 87 3 3 414 c2 324
-1 417 -10 423 -16 9 -152 11 -161 1z"/>
<path d="M2025 6197 c-83 -28 -134 -66 -180 -135 -87 -131 -99 -386 -26 -547
46 -101 144 -165 252 -165 49 0 133 28 160 53 l22 21 -5 -48 c-7 -57 -28 -89
-91 -136 l-46 -34 32 -58 c17 -32 36 -58 42 -58 21 0 127 70 156 102 66 76 81
158 77 423 l-3 170 -85 0 -85 0 -5 -95 c-6 -105 -24 -146 -80 -175 -70 -36
-130 -13 -168 64 -26 53 -27 64 -27 196 0 131 2 144 27 197 41 88 97 113 189
88 20 -6 53 -21 73 -35 20 -14 38 -25 40 -25 2 0 23 25 46 56 40 53 41 56 24
75 -9 10 -42 31 -72 46 -45 22 -72 28 -141 30 -52 2 -102 -2 -126 -10z"/>
<path d="M2534 6201 c-2 -2 -4 -193 -4 -423 l0 -419 87 3 86 3 -1 260 c-1 209
1 256 11 241 77 -117 178 -275 242 -378 l80 -128 73 0 72 0 -2 423 -3 422 -82
3 -83 3 -2 -257 -3 -257 -76 114 c-41 63 -116 175 -167 251 l-92 136 -66 4
c-36 1 -68 1 -70 -1z"/>
<path d="M3317 6204 c-4 -4 -7 -196 -7 -426 l0 -418 90 0 90 0 -2 423 -3 422
-80 3 c-45 1 -84 0 -88 -4z"/>
<path d="M3547 6204 c-4 -4 -7 -36 -7 -70 0 -73 4 -76 103 -74 34 1 72 1 85 1
l22 -1 0 -350 0 -350 84 0 84 0 5 48 c2 26 3 183 1 349 -2 223 1 303 9 306 7
2 49 4 95 4 46 0 87 4 92 9 5 5 11 36 12 69 l3 60 -290 3 c-160 1 -294 0 -298
-4z"/>
<path d="M4205 6202 c-3 -3 -5 -194 -5 -424 l0 -418 265 0 265 0 0 70 0 70
-173 0 c-96 0 -176 2 -178 4 -2 2 -3 52 -1 110 l3 106 117 0 c64 0 122 4 128
8 11 7 34 129 26 137 -3 3 -65 5 -139 5 l-133 0 0 99 0 99 145 -5 c195 -7 190
-9 193 71 3 55 1 65 -15 70 -22 6 -491 4 -498 -2z"/>
<path d="M4820 6189 c-1 -33 -1 -801 0 -818 0 -11 33 -12 183 -9 159 4 189 8
240 27 107 42 174 109 213 215 15 41 19 78 19 186 -1 154 -18 214 -80 287 -79
93 -167 121 -402 130 l-173 6 0 -24z m328 -140 c44 -14 89 -54 112 -99 63
-124 37 -348 -48 -409 -38 -27 -107 -43 -167 -39 l-50 3 -3 270 c-1 148 0 275
2 281 5 12 105 8 154 -7z"/>
<path d="M6114 6196 c-3 -7 -4 -40 -2 -72 l3 -59 102 -3 102 -3 1 -312 c0
-172 0 -329 0 -349 l0 -38 85 0 85 0 0 350 0 350 95 0 c109 0 111 2 117 97 l3
48 -293 2 c-238 2 -294 0 -298 -11z"/>
<path d="M6923 6198 c-4 -7 -44 -110 -89 -228 -45 -118 -110 -287 -144 -375
-34 -88 -68 -177 -75 -197 l-14 -38 88 0 88 0 39 105 38 106 135 -3 134 -3 33
-92 c18 -51 35 -97 39 -103 6 -10 185 -15 185 -5 0 8 -118 320 -195 515 -42
107 -86 220 -97 250 -29 77 -32 80 -99 80 -35 0 -61 -5 -66 -12z m108 -363
c20 -63 34 -117 31 -120 -10 -9 -152 -5 -152 5 0 5 4 19 9 32 6 13 22 62 37
111 15 48 30 87 33 87 4 0 23 -52 42 -115z"/>
<path d="M7533 6181 c-160 -76 -188 -265 -56 -381 21 -19 80 -53 132 -78 110
-51 130 -71 131 -130 0 -93 -76 -115 -224 -67 -42 14 -78 25 -80 25 -6 0 -66
-99 -66 -108 0 -16 110 -63 182 -78 90 -19 161 -13 235 22 124 59 177 219 113
344 -26 51 -49 68 -178 135 -104 54 -118 64 -130 97 -12 30 -12 41 0 65 20 43
68 51 168 26 l82 -19 38 50 c21 28 36 54 34 58 -7 10 -64 35 -124 54 -25 7
-79 14 -120 14 -62 0 -85 -5 -137 -29z"/>
<path d="M8007 6203 c-10 -10 -8 -528 2 -600 12 -78 35 -127 83 -174 66 -64
193 -92 310 -69 111 22 175 68 220 159 22 45 23 56 26 364 l3 317 -84 0 -85 0
-4 -292 c-3 -280 -4 -295 -26 -339 -26 -54 -54 -69 -123 -69 -65 0 -104 21
-129 70 -19 37 -20 58 -20 334 0 259 -2 295 -16 300 -21 8 -149 8 -157 -1z"/>
<path d="M8773 6196 c-9 -14 -12 -446 -5 -664 l5 -174 181 4 c156 4 188 7 235
26 143 57 198 202 125 329 -13 22 -36 50 -51 61 l-27 20 29 33 c39 48 55 90
55 151 0 88 -43 152 -129 191 -71 31 -402 50 -418 23z m305 -142 c81 -32 84
-142 5 -183 -12 -6 -49 -11 -82 -11 l-61 0 0 98 c0 54 3 102 7 105 12 13 92 7
131 -9z m-1 -340 c55 -11 76 -36 81 -97 4 -52 2 -58 -25 -86 -24 -24 -41 -30
-96 -36 -99 -11 -97 -14 -97 115 l0 110 54 0 c29 0 67 -3 83 -6z"/>
<path d="M5744 6163 c-9 -21 -37 -94 -64 -163 -26 -69 -89 -233 -140 -365 -51
-132 -95 -248 -97 -258 -5 -16 2 -18 83 -15 l89 3 35 100 36 100 137 3 c105 2
138 0 141 -10 2 -7 18 -55 36 -105 l32 -93 95 0 c72 0 94 3 90 13 -12 27 -139
355 -220 567 -47 124 -89 233 -92 243 -6 14 -20 17 -76 17 l-69 0 -16 -37z
m122 -325 c19 -57 34 -109 34 -115 0 -9 -22 -13 -75 -13 -53 0 -75 4 -75 12 0
7 14 56 31 108 17 52 33 101 35 109 7 23 15 7 50 -101z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
